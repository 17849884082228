(function () {
  'use strict';

  let angular = window.angular;

  PublicRouter.$inject = ['$stateProvider'];

  angular.module('app').config(PublicRouter);

  function PublicRouter($stateProvider) {
    $stateProvider
      .state('member', {
        url: '/member?:style',
        redirectTo: 'member.home',
        authenticated: true,
        template:'<div ng-if="$root.systemMessage.show || $root.appErrorMessage" class="system-message"><div class="text-center no-margin alert alert-{{$root.systemMessage.level || \'info\'}}" ng-if="$root.systemMessage.show"><button class="close" ng-click="$root.systemMessage.show=false" ng-if="$root.systemMessage.allowClose"><i class="fas fa-times fa-fw" style="font-size: 15px"></i></button><div ng-bind-html="$root.systemMessage.text"></div></div><div class="text-center no-margin alert alert-{{$root.appErrorMessage.level || \'info\'}}" ng-if="$root.appErrorMessage.show"><button class="close" ng-if="$root.appErrorMessage.allowClose" ng-click="$root.appErrorMessage.show=false"><i class="fas fa-times fa-fw" style="font-size: 15px"></i></button><div ng-bind-html="$root.appErrorMessage.text"></div></div></div><div class="absolute" ng-style="{top: $root.systemMessage.show || $root.appErrorMessage.show? \'36px\': 0}"><nav-bar></nav-bar><div id="page-wrapper"><div class="absolute" style="overflow-y: auto"><ui-view></ui-view></div><spinner show="$root.viewLoading" css-class="\'dark\'"></spinner></div></div>',
        params: {
          style: { type: 'string', inherit: true },
        },
        resolve: {
          user: [
            'RouterService',
            'UserService',
            'AppUtils',
            '$rootScope',
            function (RouterService, UserService, utils, $root) {
              return UserService.getCurrent()
                .then((current) => {
                  RouterService.storeId(current.id, 'Principal');
                  return RouterService.getPrincipal(current, ['container', 'permission', 'views']);
                })
                .then((user) => {
                  user.image = UserService.getUserImageUrl(user);
                  user.views = utils.arrayToObject(user.views, 'module');
                  user.isMaster = user.permission?.master;
                  $root.isMaster = user.permission?.master;
                  $root.user = user;
                  return user;
                })
                .catch((err) => {
                  console.log(err);
                  throw utils.getHTTPError(err, { redirectTo: 'public.login' });
                });
            },
          ],
          isMaster: [
            'user',
            function (user) {
              return user.permission?.master;
            },
          ],
          isAdmin: () => false,
          organization: [
            'user',
            'RouterService',
            '$rootScope',
            function (user, RouterService, $root) {
              return RouterService.getCustomer(user).then((organization) => {
                $root.customerId = organization.id;
                $root.customer = organization;
                // if (organization.varId === '5c464960bcd83b0010077e21') {
                //   $root.systemMessage = {
                //     level: 'danger',
                //     show: true,
                //     text: `Pago pendiente, por favor contacte a su proveedor.`,
                //     allowClose: false,
                //   };
                // }
                return organization;
              });
            },
          ],
          customer: [
            'organization',
            '$q',
            function (organization, $q) {
              return $q.resolve(organization);
            },
          ],
          managerCount: [
            'user',
            'RouterService',
            (user, RouterService) => {
              return RouterService.getManagersCount(user);
            },
          ],
          projects: [
            'RouterService',
            'user',
            'navbarService',
            function (RouterService, user, navbarService) {
              return RouterService.getProjects({
                customerId: user.customerId,
              }).then((projects) => {
                navbarService.setProjects(projects);
                return projects;
              });
            },
          ],
          yoloClasses: [
            'RouterService',
            '$rootScope',
            (RouterService, $rootScope) => {
              return RouterService.getYoloClasses().then((classes) => {
                $rootScope.yoloClasses = classes;
                return classes;
              });
            },
          ],
        },
        ncyBreadcrumb: {
          label: '<i class="fas fa-home"></i> Home',
        },
      })
      .state('member.home', {
        url: '/home',
        authenticated: true,
        component: 'customerProjects',
        ncyBreadcrumb: { skip: true },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/editors/simplemde.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
              ]);
            },
          ],
        },
      })
      .state('member.dataDisplay', {
        url: '/data-display/:projectId/:assetId/:sensorId?:dataId&:zoneFilter',
        component: 'monitoringDataDisplay',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Data Display',
        },
        resolve: {
          project: [
            'RouterService',
            'Manager',
            '$transition$',
            'navbarService',
            'AppUtils',
            function (RouterService, Manager, $transition$, navbarService, utils) {
              let params = $transition$.params();
              let user = Manager.getCachedCurrent();
              RouterService.storeId(params.projectId, 'Customer$CurrentProject');
              return RouterService.getProject({
                customerId: user.customerId,
                projectId: params.projectId,
              })
                .then((project) => {
                  navbarService.setProject(project);
                  return project;
                })
                .catch((err) => {
                  throw utils.getHTTPError(err, {
                    redirectTo: 'member.home',
                    params: { customerId: user.customerId },
                  });
                });
            },
          ],
        },
      });
  }
})();
